.admin .xs__control {
  min-height: 29px;
  height: 29px;
  font-size: 14px;
  align-items: center;
}

.admin .xs__control:hover {
  border-color: #00aeef;
}

.admin .xs__control--is-focused {
  border-color: #00aeef;
}

.admin .xs__control--is-focused:hover {
  border-color: #00aeef;
}

.admin .xs__value-container {
  padding: 0 8px;
}

.admin .xs__dropdown-indicator {
  padding: 0 8px;
  width: 34px;
  color: #00aeef;
}

.admin .xs__menu {
  z-index: 11000;
  font-size: 12px;
}

.admin .sm__menu {
  z-index: 11000;
  font-size: 14px;
}

.admin .sm__indicator {
  padding: 0 4px;
}

.admin .sm__control {
  min-height: 35px;
  height: 35px;
  font-size: 14px;
  align-items: center;
}

.admin .sm__value-container {
  padding: 0 8px;
}

.admin .sm__dropdown-indicator {
  padding: 0 8px;
  width: 34px;
  color: #00aeef;
}

.admin .xs__indicator {
  padding: 0 4px;
}
